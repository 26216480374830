.nav {
  width: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px 0 rgb(0 0 0 / 13%);
  background: white;
  max-height: 100%;

  a {
    &:focus-visible {
      outline-offset: 2px;
      outline: var(--accent-d) dashed 2px;
    }
  }

  &__Content {
    max-width: 1200px;
    display: flex;
    flex: 1;
    justify-content: space-between;
    align-items: center;
    padding: 10px 0;

    > *:not(:last-child) {
      margin-right: 2rem;
    }

    img {
      height: 60px;
      width: auto;

    }

    &__Logo-Wrapper {
      height: 60px;
      max-height: 60px;
    }

  }

  ul {
    list-style-type: none;
    display: grid;
    align-items: center;
    grid-column-gap: 1rem;
    grid-auto-flow: column;
    flex: 1;

    li {
      text-align: center;
    }

    a {
      color: black;
      font-weight: bold;
      text-decoration: none;
      font-size: 1.125rem;

      &:hover,
      &:focus {
        text-decoration: underline;
      }

      @media only screen and (max-width: 950px) {
        font-size: 0.8rem;
      }

      /*&:focus-visible {
        outline-offset: 2px;
        outline: var(--accent-d) dashed 2px;
      } */
    }
  }

  tremaze-mobile-menu-trigger {
    display: none;
  }

  @media only screen and (max-width: 800px) {
    ul {
      display: none;
    }
    .nav__Content__Auth-Btn {
      margin-left: auto;
    }

    .nav__Content__Burger-Btn {
      display: contents;
    }
  }
}

// Accessibility CSS

@media only screen and (max-height: 300px) {
  .nav {
    padding: 0;

    .nav__Content > a {
      width: 40px !important;
    }

    .tremaze-image__Wrapper.tremaze-image__Wrapper--Use-Aspect-Ratio {
      padding-top: 0 !important;

      > img {
        position: unset !important;
      }
    }

    tremaze-tenant-logo {
      width: 40px !important;
    }

    .hamburger {
      padding: 4px !important;
    }
  }

}
