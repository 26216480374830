.tremaze-auth-button {
  &__Logged-In {
    --rad: var(--button-r);

    min-width: 128px;

    &__Trigger {
      min-width: 100%;
      transition: border-raidus 200ms ease, background-color 200ms ease,
        color 200ms ease;
      border-radius: var(--rad);

      &:after {
        transform: translate(50%, -50%) rotateZ(90deg) !important;
        transition: transform 300ms ease-in-out;
      }
    }

    &--Expanded {
      > .tremaze-auth-button__Logged-In__Trigger {
        border-radius: var(--rad) var(--rad) 0 0;
        background-color: var(--accent);
        color: white;

        &:after {
          transform: translate(50%, -50%) rotateZ(270deg) !important;
        }

        &:hover,
        &:focus {
          background-color: var(--accent-d);
        }
      }
    }

    &__Nav {
      --rad: var(--button-r);
      min-width: 100%;
      transition: inherit;
      overflow: hidden;
      border-radius: 0 0 var(--rad) var(--rad);

      ul {
        display: flex;
        flex-direction: column;

        li {
          text-align: left;

          > * {
            width: 100%;
            height: 100%;
            display: block;
            border: none;
            outline: none;
            font-size: 1rem;
            padding: 0.75em 1em;
            text-align: left;
            cursor: pointer;
            text-decoration: none !important;
            background-color: var(--accent);
            color: var(--accent-fg);

            .lnr {
              margin-right: 0.5em;
            }

            &:hover,
            &:focus {
              background-color: var(--accent-d);
            }
          }
        }
      }
    }
  }

  &__Icon {
    display: none;
  }
}

@media only screen and (max-width: 450px) {
  .tremaze-auth-button {
    &__Full-Name {
      display: none;
    }

    &__Icon {
      display: inline;
    }
  }
}
